import { createRouter, createWebHistory } from 'vue-router'

function load(view) {
  return () => import(`../views/${view}.vue`)
}
const DEFAULT_TITLE = process.env.VUE_APP_TITLE;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: load('Home'),
    meta: { title: DEFAULT_TITLE }
  },
  {
    path: '/gioi-thieu',
    name: 'About',
    component: load('About')
  },
  {
    path: '/chu-de/:id?',
    name: 'News',
    component: load('News')
  },
  {
    path: '/noi-dung/:id?',
    name: 'Page',
    component: load('Page')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  // document.title = "my blog - " + to.meta.title || DEFAULT_TITLE;
});

export default router
